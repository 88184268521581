<template>

  <NavBar></NavBar>

  <main class="container-lg">

    <h3 class="text-center" id="sec_fp">Farmácia Popular</h3>

    <div id="reader" class="mx-auto my-3">
      <div class="laser-line"></div>
    </div>

    <div class="row">

      <div class="col-12 col-md-6">
        <BarCodeScanner v-model:ean="codigo" @scannerSucess="pesquisar()"></BarCodeScanner>
      </div>
      <div class="col-12 col-md-6">
        <label for="codigo" class="form-label">Estado</label>
        <select id="estado" name="estado" class="form-select" v-model="estado" aria-label="Default select example">>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO" selected>Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </select>
      </div>
      <div class="col-sm-12 col-md pt-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox"  role="switch" v-model="bf" value="" id="fp_check">
          <label class="form-check-label" for="fp_check">
            Bolsa Família
          </label>
        </div>
      </div>

      <div class="col-12 text-center mt-2">
        <button class="btn btn-primary" v-on:click="pesquisar">Pesquisar</button>
      </div>

      <div class="col-12" v-if="medicamento">
        <table class="table table-striped caption-top">
          <caption>Produto encontrado</caption>
          <tbody>
          <tr v-if="medicamento.NOME">
            <td><strong>Nome</strong></td>
            <td>{{ medicamento.NOME }}</td>
          </tr>
          <tr v-if="medicamento.FABRICANTE">
            <td><strong>Fabricante</strong></td>
            <td>{{ medicamento.FABRICANTE }}</td>
          </tr>
          <tr>
            <td><strong>Principio ativo/Insumo</strong></td>
            <td>{{ medicamento.PRINCIPIO_ATIVO }}</td>
          </tr>
          <tr>
            <td><strong>Modalidade</strong></td>
            <td>{{ medicamento.CATEGORIA }}</td>
          </tr>
          <tr>
            <td><strong>Patologia</strong></td>
            <td>{{ medicamento.INDICACAO }}</td>
          </tr>
          <tr>
            <td><strong>Código de barras</strong></td>
            <td>{{ medicamento.CODIGO_BARRA }}</td>
          </tr>
          <tr aria-colspan="2" class="table-group-divider"></tr>
          </tbody>
        </table>
      </div>

      <div class="col" v-if="apresentacoes.length > 1">

        <div class="alert alert-danger" role="alert">
          O principio ativo desse produto possui mais de apresentação. Selecione a apresentação correspondente a esse código de barras!
        </div>

        <label for="apresentacao" class="form-label">Apresentações</label>
        <select @change="selectedApresentacao($event)" id="apresentacao" name="apresentacao" class="form-select" aria-label="Default select example">>
          <option value="">Selecione a apresentação adequada</option>
          <option  v-for="(apresentacao, i) in apresentacoes" :key="i" :value="i">{{ apresentacao.PRINCIPIO_ATIVO }}</option>
        </select>
      </div>

      <div class="col-12" v-if="valor">
        <table class="table table-striped caption-top">
          <caption>Valores</caption>
          <tbody>
          <tr v-if="valor.APRESENTACAO">
            <td><strong>Unidade farmotécnica(UF)</strong></td>
            <td>{{ valor.APRESENTACAO }}</td>
          </tr>
          <tr v-if="medicamento.QUANTIDADE">
            <td><strong>Quantidade</strong></td>
            <td>{{ medicamento.QUANTIDADE }}</td>
          </tr>
          <tr v-if="valor.VALOR_REFERENCIA">
            <td><strong>Valor de referência por UF</strong></td>
            <td>R$ {{ valor.VALOR_REFERENCIA }}</td>
          </tr>
          <tr v-if="valor.VALOR_MAXIMO">
            <td><strong>Valor pago pelo MS por UF</strong></td>
            <td>R$ {{ valor.VALOR_MAXIMO }}</td>
          </tr>
          <tr v-if="estado in valor">
            <td><strong>Valor pago pelo MS por UF para o estado de {{ estado }}</strong></td>
            <td>R$ {{ valor[estado] }}</td>
          </tr>
          <tr v-if="calcValorFinal() !== null">
            <td><strong>Preço final pago pelo MS</strong></td>
            <td>R$ {{ calcValorFinal() }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div class="w-100 border-bottom my-2"></div>

    <div class="row  align-items-center">
      <h3 class="text-center" id="sec_sngpc">SNGPC</h3>
      <div class="col col-md-4 col-12">
        <img src="./assets/sngpc.jpg" class="img-fluid">
      </div>
      <div class="col">
        <p>O Sistema Nacional de Gerenciamento de Produtos Controlados, o SNGPC, monitora as movimentações de entrada (compras e transferências) e saída (vendas, transformações, transferências e perdas) de medicamentos comercializados em farmácias e drogarias privadas do país, particularmente os medicamentos sujeitos à Portaria 344/1998 (como os entorpecentes e os psicotrópicos) e os antimicrobianos.</p>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Portaria 344/98 (Psicotrópicos)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Anexo I - P.344 (Atualizado 2021)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>RDC 471/2021 (Antimicrobianos)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>IN 83 (Lista Antimicrobianos)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>RDC 58/2007 (RMNRB2)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>RDC 133/2016 (RMNRB2)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Manual BSPO (Manipulação)</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Sistema de Segurança da ANVISA</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>SNGPC - Empresa</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Orientações SNGPC - Empresa</a>
      </div>
      <div class="col col-md-6 text-center border rounded">
        <a href="#" target=_blank>Consulta Medicamentos</a>
      </div>

    </div>

    <div class="w-100 border-bottom my-2"></div>

    <h3 class="text-center" id="sec_links">Links Úteis</h3>

    <ul  class="list-group list-group-flush">
      <li class="list-group-item">
        <a href="#" target=_blank>BAIXE O AMMYY</a>
      </li>
      <li class="list-group-item">
        <a href="#" target=_blank>Conferir Status Servidor SEFAZ</a>
      </li>

      <li class="list-group-item">
        <a href="#" target=_blank>Pesquisa NCM no Cosmos</a>
      </li>
      <li class="list-group-item">
        <a href="#" target=_blank>Farm&aacute;cia Popular</a>
      </li>

      <li class="list-group-item">
        <a href="#" target=_blank>Pesquisa de CBO</a>
      </li>
      <li class="list-group-item">
        <a href="#" target=_blank>Consulta de AFE</a>
      </li>

      <li class="list-group-item">
        <a href="#" target=_blank >Consulta CNPJ - Receita Federal</a>
      </li>
      <li class="list-group-item">
        <a href="#" target=_blank >Consulta SINTEGRA - Goiás</a>
      </li>
    </ul>

  </main>


  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modal-default :show="showModal" @close="onCloseModal">
      <template #header>
        <h3>{{ modalTitle }}</h3>
      </template>
      <template #body>
        {{ modalBody }}
      </template>
    </modal-default>
  </Teleport>

</template>

<script>
import NavBar from "@/components/NavBar";
import ModalDefault from "@/components/ModalDefault";
import axios from "axios"
import BarCodeScanner from "@/components/BarCodeScanner.vue";

export default {
  name: 'App',
  components: {
    NavBar,
    ModalDefault,
    BarCodeScanner
  },
  data(){
    return {
      codigo: null,
      medicamento: null,
      valor: null,
      bf: false,
      estado: "GO",
      showModal: false,
      modalTitle: '',
      modalBody: '',
      apresentacoes: [],
      ListaMedicamentos: null,
      ValoresGratuitos: null,
      ValoresCopagamento: null,
      ValoresBolsaFamilia: null
    }
  },
  beforeMount() {
    axios.get('/json/medicamentos.json')
        .then(response => (this.ListaMedicamentos = response.data))

    axios.get('/json/valor_bolsa_familia.json')
        .then(response => (this.ValoresBolsaFamilia = response.data))

    axios.get('/json/valor_copagamento.json')
        .then(response => (this.ValoresCopagamento = response.data))

    axios.get('/json/valor_gratuitos.json')
        .then(response => (this.ValoresGratuitos = response.data))
  },
  methods: {
    findCodigoBarras(cod){
      for (var key in this.ListaMedicamentos){
        if(this.ListaMedicamentos[key].CODIGO_BARRA == cod){
          return this.ListaMedicamentos[key];
        }
      }
      throw "Código de barras não encontrado";
    },
    findValorMedicamento(med,bol_fam=false){

      let apresent = [];

      //GRATUITO
      if (med.CATEGORIA.includes("GRATUITO")){
        for (var i in this.ValoresGratuitos){
          if(this.ValoresGratuitos[i].PRINCIPIO_ATIVO.includes(med.PRINCIPIO_ATIVO)){
            apresent.push(this.ValoresGratuitos[i]);
          }
        }

        if(apresent.length > 0){
          return apresent;
        }

        throw "Valores não encontrados para a categoria: GRATUITOS";
      }

      //BOLSA FAMILIA
      if(bol_fam){
        for (var j in this.ValoresBolsaFamilia){
          if(this.ValoresBolsaFamilia[j].PRINCIPIO_ATIVO.includes(med.PRINCIPIO_ATIVO)){
            apresent.push(this.ValoresBolsaFamilia[j]);
          }
        }

        if(apresent.length > 0){
          return apresent;
        }

        throw "Valores não encontrados para a categoria: BOLSA FAMÍLIA";
      }

      //COPAGAMENTO
      for (var k in this.ValoresCopagamento){
        if(this.ValoresCopagamento[k].PRINCIPIO_ATIVO.includes(med.PRINCIPIO_ATIVO)){
          apresent.push(this.ValoresCopagamento[k]);
        }
      }

      if(apresent.length > 0){
        return apresent;
      }

      throw "Valores não encontrados";
    },
    pesquisar(){
      this.medicamento = null;
      this.valor = null;
      this.apresentacoes = [];

      console.log(this.codigo)

      try{
        this.medicamento = this.findCodigoBarras(this.codigo)

        if(this.medicamento){
          this.apresentacoes = this.findValorMedicamento(this.medicamento, this.bf)

          if(this.apresentacoes.length === 1){
            this.valor = this.apresentacoes[0]
          }
        }

      }catch (err){
        this.modalTitle = "Erro";
        this.modalBody = err;
        this.showModal = true;
      }
    },
    selectedApresentacao(event){
      let index = event.target.value;
      if (typeof this.apresentacoes[index] !== 'undefined') {
        this.valor = this.apresentacoes[index];
      }else{
        this.valor = null;
      }
    },
    onCloseModal(){
      this.showModal = false;
    },
    calcValorFinal(){
      console.log(this.medicamento, this.valor)
      if(! this.medicamento || !this.valor){
        return null;
      }

      if(this.medicamento.QUANTIDADE === null || this.medicamento.QUANTIDADE <= 0){
        return null;
      }

      if(this.valor.VALOR_MAXIMO){
        return  Number.parseFloat(this.medicamento.QUANTIDADE) * Number.parseFloat(this.valor.VALOR_MAXIMO);
      }

      if(this.estado in this.valor){
        return  Number.parseFloat(this.medicamento.QUANTIDADE) * Number.parseFloat(this.valor[this.estado]);
      }

      return null;
    }
  }

}
</script>

<style scoped>

/*small port - celular*/
@media screen and (max-width: 576px){
  #reader{
    width: auto;
    max-width: 370px;
  }
}

/*media port - table*/
@media screen and (min-width: 768px){
  #reader{
    width: 400px;
  }
}

/*large port - pc*/
@media screen and (min-width: 992px){

  #reader{
    width: 450px;
  }
}
</style>
