<template>

    <div class="form-group">
        <label for="barcode-ean" class="form-label">Código de barras</label>
        <div :class="cameras.length > 0 ? 'input-group' : ''">
            <input type="number" :value="ean" @input="onInputEan" class="form-control" id="barcode-ean" placeholder="Código de barras" aria-label="Código de barras" aria-describedby="barcode-ean">
            <button v-show="camOn && cameras.length > 0" class="btn btn-outline-secondary text-red-600" type="button" @click="stopBarCode()"><i class="bi bi-camera-video-off"></i>&nbsp;</button>
            <button v-show="!camOn && cameras.length > 0" class="btn btn-outline-secondary" type="button" @click="startBarCode()"><i class="bi bi-camera"></i>&nbsp;</button>
            <button type="button"  v-if="cameras.length > 0" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu dropdown-menu-end" v-if="cameras.length > 0">
                <li v-for="(camera, i) in cameras" :key="i"><a class="dropdown-item" @click="chooseCamera(camera.id)">{{ camera.label }}</a></li>
            </ul>
        </div>
    </div>

</template>

<script>
import {Html5Qrcode, Html5QrcodeSupportedFormats} from 'html5-qrcode';
import beep from '../assets/barcode-beep-sound.mp3';


export default {
    name: "BarCodeScanner",

    props:{
        ean: Number
    },
    data(){
        return{
            config:{
                fps: 10,
                qrbox: { width: 150, height: 50 },
                aspectRatio: 1,
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.EAN_13,
                    Html5QrcodeSupportedFormats.EAN_8,
                    Html5QrcodeSupportedFormats.UPC_A,
                    Html5QrcodeSupportedFormats.UPC_E,
                    Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION
                ],
                useBarCodeDetectorIfSupported: !0,
                rememberLastUsedCamera: !0,
                showTorchButtonIfSupported: !0,
                showZoomSliderIfSupported: !0,
                defaultZoomValueIfSupported: 1.5,
            },
            barCodeScanner: null,
            camOn: false,
            cameras:[],
            cameraId: '',
            audiobeep: null
        }
    },
    emits: [
        'update:ean',
        'scannerSucess',
        'scannerFail'
    ],
    methods:{
        startBarCode(){
            this.audiobeep = new Audio(beep);

            Html5Qrcode.getCameras().then(devices => {
                /**
                 * devices would be an array of objects of type:
                 * { id: "id", label: "label" }
                 */
                if (devices && devices.length) {
                    this.barCodeScanner = new Html5Qrcode("reader");

                    this.calcAspectRatio()

                    // If you want to prefer back camera
                    this.barCodeScanner.start({deviceId: {exact: this.cameraId}}, this.config, this.onSucessBarCode, this.onFailBarCode);
                    this.camOn = true;

                }else{
                    this.$toast.error(`Nenhuma câmera encontrada`,{position: 'top'});
                }
            }).catch(() => {
                this.$toast.error(`Erro desconhecido ao iniciar a câmera`,{position: 'top'});
            });

        },
        stopBarCode(){
            if(this.barCodeScanner) {
                if(this.barCodeScanner.getState() != 1){
                    this.barCodeScanner.stop();
                }
            }
            this.camOn = false;
        },
        onSucessBarCode(decodedText) {
            if(this.audiobeep){
                this.audiobeep.play();
            }

            this.$emit('update:ean', Number.parseInt(decodedText));
            this.$emit('scannerSucess',Number.parseInt(decodedText));

            this.barCodeScanner.clear();
        },
        onFailBarCode(){
            this.$emit('scannerFail',true);
        },
        chooseCamera(id){
            this.cameraId = id;
            this.stopBarCode();
            this.startBarCode();
        },
        onInputEan(event){
            let value = event.target.value;

            if(value.length <= 13) {
                this.$emit('update:ean',Number.parseInt(event.target.value));
            }
            else{
                event.target.value = Number.parseInt(value.slice(0,13));
            }
            this.$forceUpdate();
        },
        calcAspectRatio(){
            let orientation = window.matchMedia("(orientation: portrait)").matches ? "portrait" : "landscape";
            let mobile = /Android|iPhone/i.test(navigator.userAgent);

            // let aspectRatio = (orientation === "portrait" && mobile) ? window.screen.width / window.screen.height : window.screen.height / window.screen.width;

            let aspectRatio = window.screen.width / window.screen.height;
            console.log(orientation, mobile, window.screen.width, window.screen.height, aspectRatio)

            this.config.aspectRatio = aspectRatio;
            return aspectRatio;
        },
      getPermissionCam(){
        Html5Qrcode.getCameras().then(devices => {
          if (devices && devices.length) {
            this.cameras = devices;
            this.cameraId = devices[0].id;
          }
        })
      }
    },
    mounted() {
       this.getPermissionCam()
    },
    beforeUnmount() {
        this.stopBarCode();
    }
}
</script>

<style>
/*O tamanho do background deve ser o mesmo do qrbox*/
div#qr-shaded-region {
    background: url('../assets/line.png') no-repeat;
    background-size: 150px;
    animation: slide 1.1s linear infinite alternate-reverse;
}

@keyframes slide {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 100%;
    }
}
</style>
