import { createApp } from 'vue'
import App from './App.vue'

import Toaster from '@meforma/vue-toaster';

//import boostrap css e js
import './sass/app.scss'
import "bootstrap"

createApp(App).use(Toaster).mount('#app')
